import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from 'types';

export interface TagState {
    tag: Tag;
    isLoading: boolean;
}

export const EMPTY_TAG: Tag = {
    id: 0,
    name: '',
    slug: '',
    locale: null,
    localizations: []
}

const initialTagState: TagState = {
    tag: EMPTY_TAG,
    isLoading: false,
};

const tagSlice = createSlice({
    name: 'tag',
    initialState: initialTagState,
    reducers: {
        setTag(state, action: PayloadAction<Tag>) {
            state.tag = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setTag } = tagSlice.actions;
export const tagReducer = tagSlice.reducer;
export default tagSlice.reducer;
