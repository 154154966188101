import { SessionTypes, SignClientTypes } from '@walletconnect/types';

import { proxy } from 'valtio';

/**
 * Types
 */
interface ModalData {
    walletConnect?: {
        proposal?: SignClientTypes.EventArguments['session_proposal'];
        requestEvent?: SignClientTypes.EventArguments['session_request'];
        requestSession?: SessionTypes.Struct;
    };
    local?: {
        open?: boolean;
    };
}

interface State {
    walletConnect?: {
        open: boolean;
        view?:
            | 'SessionProposalModal'
            | 'SessionSignModal'
            | 'SessionSignTypedDataModal'
            | 'SessionSendTransactionModal'
            | 'SessionUnsuportedMethodModal';
        data?: ModalData;
    };
    local?: {
        open: boolean;
    };
    wrongNetwork: {
        open: boolean;
    };
    importToken: {
        open: boolean;
    };
    send: {
        open: boolean;
    };
}

/**
 * State
 */
const state = proxy<State>({
    walletConnect: {
        open: false,
    },
    local: {
        open: false,
    },
    wrongNetwork: {
        open: false,
    },
    importToken: {
        open: false,
    },
    send: {
        open: false,
    },
});

/**
 * Store / Actions
 */
const ModalStore = {
    state,

    openWalletConnect(view: any, data: any) {
        state.walletConnect = {
            open: true,
            view,
            data,
        };
    },

    closeWalletConnect() {
        state.walletConnect = { open: false };
    },

    openLocal() {
        state.local = { open: true };
    },

    closeLocal() {
        state.local = { open: false };
    },

    openWrongNetwork() {
        state.wrongNetwork = { open: true };
    },

    openImportToken(payload: boolean) {
        state.importToken = { open: payload };
    },

    closeWrongNetwork() {
        state.wrongNetwork = { open: false };
    },

    openSend() {
        state.send = { open: true };
    },

    closeSend() {
        state.send = { open: false };
    },
};

export default ModalStore;
