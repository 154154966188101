import { ReactElement, } from 'react';
import useDarkMode from '@/web-sdk/hooks/useDarkmode';

interface Props {
    children: ReactElement;
}

export const ColorThemeProvider: React.FC<Props> = ({ children }: Props) => {
    useDarkMode();

    return children;
};
