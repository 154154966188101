import { isBrowser } from '@/web-sdk/helpers/isBrowser';
import { useAppDispatch } from '@/web-sdk/providers/ReduxProvider/app/hooks';
import {
    changeTheme,
    toggleChartRebuild,
} from '@/web-sdk/providers/ReduxProvider/features/globalSettings/globalSettingsSlice';
import { useCallback, useEffect, useState } from 'react';

export const useDarkMode = () => {
    const [theme, setTheme] = useState(isBrowser() && window.localStorage.theme ? window.localStorage.theme : 'light');

    const dispatch = useAppDispatch();

    const setNewTheme = useCallback(() => setTheme(theme === 'dark' ? 'light' : 'dark'), [theme]);

    const colorTheme = theme === 'dark' ? 'light' : 'dark';

    useEffect(() => {
        if (isBrowser()) {
            dispatch(changeTheme(window.localStorage.theme ? window.localStorage.theme : 'light'));
            setTheme(window.localStorage.theme ? window.localStorage.theme : 'light');
        }
    }, [isBrowser()]);

    useEffect(() => {
        const root = window.document.body;

        root.classList.remove(colorTheme);
        root.classList.add(theme);

        if (isBrowser()) {
            dispatch(changeTheme(theme));
            dispatch(toggleChartRebuild());
            window.localStorage.setItem('theme', theme);
            window.localStorage.setItem('tradingview.current_theme.name', theme);
        }
    }, [theme]);

    return [theme, setNewTheme];
};

export default useDarkMode;
