import { useCallback, useEffect, useMemo, useRef, useState, FC, ReactNode } from 'react'
import classnames from 'classnames';
import Link from 'next/link';

interface NavItem {
    name: string | ReactNode;
    submenu?: any[];
}

export interface FooterOptions {
    navigations: NavItem[],
    socials: any[],
    socialIconNumDisplay?: number,
    upIcon: () => JSX.Element,
    downIcon: () => JSX.Element,
}

export interface FooterProps {
    /**
     * Footer menu content
     * navigations: Nested navigation menu
     * socials: social icons and links
     * socialIconNumDisplay: Max number of social icons to display before More button. Optional. Default: 4
     * upIcon: Up arrow image icon for dropdown
     * upIcon: Down arrow image icon for dropdown
     */
    options?: FooterOptions;
    /**
     * What class name to use.
     * We have tailwind support that's why
     * you can use their classnames
     */
    className?: string;
    /**
     * What class name to use for changing
     * styles for options inside footer.
     */
    optionClassName?: string;
    /**
     * What class name to use for changing
     * styles for dropdown.
     */
    dropdownBlockClassName?: string;
    /**
     * What class name to use for changing
     * styles for options inside dropdown.
     */
    dropdownOptionClassName?: string;
    /**
     * What class name to use for changing
     * styles for options inside footer.
     */
    socialOptionClassName?: string;
    /**
     * What class name to use for changing
     * styles for more social button.
     */
    socialMoreButtonClassName?: string;
    /**
     * What class name to use for changing
     * styles for more social button text.
     */
    socialMoreTextClassName?: string;
    /**
     * What class name to use for changing
     * styles for more social dropdown.
     */
    socialMoreClassName?: string;
    /**
     * Text label for "More" button
     */
    socialMoreLabel?: string | ReactNode;
}

const DEFAULT_SOCIAL: number = 4;

export const Footer: FC<FooterProps> = ({
    options,
    className = '',
    dropdownBlockClassName = 'absolute bottom-8 w-40 bg-white rounded shadow r-2 select-none',
    dropdownOptionClassName = 'block text-gray-400 hover:text-gray-500 hover:bg-gray-50 rounded px-4 py-2',
    optionClassName = 'text-gray-400 hover:text-gray-500 px-5 relative',
    socialOptionClassName = 'text-gray-400 hover:text-gray-500 px-2 opacity-60 hover:opacity-100 duration-200',
    socialMoreButtonClassName = 'self-center ml-2 relative bg-gray-100 rounded flex py-1 px-2 cursor-pointer',
    socialMoreTextClassName = 'self-center text-gray-500 text-sm font-bold',
    socialMoreClassName = 'absolute bottom-8 right-0 w-40 p-4 bg-white rounded shadow r-2',
    socialMoreLabel = 'More',
}: FooterProps) => {
    const [openDropdown, setOpenDropdown] = useState<number>(-1);
    const [openSocial, setOpenSocial] = useState<boolean>(false);
    const ref = useRef<HTMLInputElement>(null);
    const defaultSocial = options?.socialIconNumDisplay ? options.socialIconNumDisplay : DEFAULT_SOCIAL;

    useEffect(() => {
        document.addEventListener('click', outsideClick, false)

        return () => {
            document.removeEventListener('click', outsideClick, false)
        }
    }, [])

    const dropdownClick = useCallback((index: number) => {
        setOpenDropdown(openDropdown === index ? -1 : index)
    }, [openDropdown])

    const outsideClick = useCallback((e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
            setOpenDropdown(-1)
        }
    }, [openDropdown])

    const socialDropdownClick = useCallback(() => {
        setOpenSocial(!openSocial)
    }, [openSocial])

    const classNames = classnames('border-t w-full sm:flex items-center justify-between pb-20 mx-auto py-4 px-4 sm:px-6 sm:pb-4 md:pb-4 lg:pb-4 lg:px-8', className);

    const renderLinkItem = useMemo(() => (isElement: boolean, optionClass: string) => (item: any, index: number) => (
        <Link
            key={index}
            href={item.href}
            target={item.target ? item.target : '_self'}
            rel="noopener noreferrer"
            className={classnames(optionClass, item.className)}
            title={item.name}
            onMouseOver={event => event.currentTarget.title = '' }
            onMouseOut={event => event.currentTarget.title = item.name }
            aria-label={item.name}
        >
            {isElement ? renderSocialItem(item.name, item.icon) : item.name}
        </Link>
    ), []);

    const renderSocialItem = useMemo(() => (name: string, SvgIcon: any) => (
        <>
            <span className="sr-only">{name}</span>
            <SvgIcon className="h-6 w-6 inline-block" aria-hidden="true" />
        </>
    ), []);

    const renderUp = useMemo(() => options ? options.upIcon() : null, [options]);
    const renderDown = useMemo(() => options ? options.downIcon() : null, [options]);

    return (
        <footer className={options ? classNames : ''}>
            {options ? (
                <>
                    <div className="leading-8 flex flex-wrap justify-center lg:pb-0" ref={ref}>
                        {options.navigations?.map((item: NavItem, index: number) => (
                            <div key={`footer-${index}`} className={optionClassName}>
                                <div className="flex cursor-pointer" onClick={() => dropdownClick(index)}>
                                    {item.name}
                                    {item.submenu?.length ?
                                        <div className="self-center ml-2 mr-2">
                                            {openDropdown === index ? renderUp : renderDown}
                                        </div>
                                        : null}
                                </div>
                                {openDropdown === index ?
                                    <div className={dropdownBlockClassName}>
                                        {item.submenu?.map(renderLinkItem(false, dropdownOptionClassName || ''))}
                                    </div> : null
                                }
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center md:order-2">
                        {options.socials?.slice(0, defaultSocial).map(renderLinkItem(true, socialOptionClassName || ''))}
                        {options.socials?.length > defaultSocial ? (
                            <>
                                <div onClick={() => socialDropdownClick()} className={socialMoreButtonClassName}>
                                    <div className={socialMoreTextClassName}>{socialMoreLabel}</div>
                                    <div className="self-center ml-2">{openSocial ? renderUp : renderDown}</div>
                                    {openSocial && <div className={socialMoreClassName}>
                                        {options.socials?.slice(defaultSocial).map(renderLinkItem(true, socialOptionClassName || ''))}
                                    </div>}
                                </div>
                            </>
                        ) : null}
                    </div>
                </>
            ) : null}
        </footer>
    );
};
