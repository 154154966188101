import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AssetArticle } from 'types';

export interface AssetArticleState {
    article: AssetArticle;
    isLoading: boolean;
}

export const EMPTY_ASSET_ARTICLE: AssetArticle = {
    id: 0,
    type: '',
    asset_code: null,
    slug: '',
    content: '',
    title: '',
    author: '',
    description: '',
    asset: null,
    state: '',
    image_url: null,
    tags: [],
    locale: null,
    localizations: []
}

const initialAssetArticleState: AssetArticleState = {
    article: EMPTY_ASSET_ARTICLE,
    isLoading: false,
};

const assetArticleSlice = createSlice({
    name: 'assetArticles',
    initialState: initialAssetArticleState,
    reducers: {
        setAssetArticle(state, action: PayloadAction<AssetArticle>) {
            state.article = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setAssetArticle } = assetArticleSlice.actions;
export const assetArticleReducer = assetArticleSlice.reducer;
export default assetArticleSlice.reducer;
