import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isBrowser } from '@/web-sdk/helpers/isBrowser';

export interface GlobalSettingsState {
    applyWindowEnvsTrigger: boolean;
    chartRebuild: boolean;
    color: string;
    isMobileDevice: boolean;
    marketSelectorActive: boolean;
    sideBarActive: boolean;
    depositModalActive: boolean;
    withdrawModalActive: boolean;
    depositAsset?: string;
    withdrawAsset?: string;
    isWalletConnectModalOpen: boolean;
    isLoginModalOpen: boolean;
    palette: string;
    connectionType: 'ws' | 'gRPC';
    customization: 'on' | 'off';
    otpEnabled: boolean;
    wsReconnect: boolean;
    isSetupMode: boolean;
    isSendRequestActive: boolean;
    isRejectRequestActive: boolean;
    isAcceptRequestActive: boolean;
    isCancelRequestActive: boolean;
    isWalletConnectStateActive: boolean;
    connectedSuccessfuly: boolean;
    finexParticipation: string;
    isOtpOpened: boolean;
}

const currentColorTheme: string = (isBrowser() && window.localStorage.getItem('theme')) || 'light';

export const initialGlobalSettingsState: GlobalSettingsState = {
    applyWindowEnvsTrigger: false,
    chartRebuild: false,
    color: currentColorTheme,
    isMobileDevice: false,
    marketSelectorActive: false,
    sideBarActive: false,
    depositModalActive: false,
    withdrawModalActive: false,
    depositAsset: undefined,
    withdrawAsset: undefined,
    isWalletConnectModalOpen: false,
    isLoginModalOpen: false,
    wsReconnect: false,
    connectionType: 'ws',
    customization: 'off',
    otpEnabled: false,
    palette: '',
    isSetupMode: false,
    isSendRequestActive: false,
    isRejectRequestActive: false,
    isAcceptRequestActive: false,
    isCancelRequestActive: false,
    isWalletConnectStateActive: false,
    connectedSuccessfuly: false,
    finexParticipation: '',
    isOtpOpened: false,
};

const globalSettingsSlice = createSlice({
    name: 'globalSettings',
    initialState: initialGlobalSettingsState,
    reducers: {
        changePalette(state, action: PayloadAction<string>) {
            state.palette = action.payload;
        },
        changeTheme(state, action: PayloadAction<string>) {
            state.color = action.payload;
        },
        changeConnectionType(state, action: PayloadAction<'ws' | 'gRPC'>) {
            state.connectionType = action.payload;
        },
        changeCustomization(state, action: PayloadAction<'on' | 'off'>) {
            state.customization = action.payload;
        },
        toggleChartRebuild(state) {
            state.chartRebuild = !state.chartRebuild;
        },
        toggleMarketSelector(state, action: PayloadAction<boolean>) {
            state.marketSelectorActive = action.payload;
            state.sideBarActive = false;
        },
        toggleMobileDevice(state, action: PayloadAction<boolean>) {
            state.isMobileDevice = action.payload;
        },
        toggleSidebar(state, action: PayloadAction<boolean>) {
            state.sideBarActive = action.payload;
            state.marketSelectorActive = false;
        },
        triggerApplyWindowEnvs(state) {
            state.applyWindowEnvsTrigger = !state.applyWindowEnvsTrigger;
        },
        toggleDepositModal(state, action: PayloadAction<{ isOpen: boolean; asset?: string }>) {
            state.depositModalActive = action.payload.isOpen;
            state.depositAsset = action.payload.asset;
        },
        toggleWithdrawModal(state, action: PayloadAction<{ isOpen: boolean; asset?: string }>) {
            state.withdrawModalActive = action.payload.isOpen;
            state.withdrawAsset = action.payload.asset;
        },
        toggleWalletConnectModalOpen(state) {
            state.isWalletConnectModalOpen = !state.isWalletConnectModalOpen;
        },
        setWalletConnectModalOpen(state, action: PayloadAction<boolean>) {
            state.isWalletConnectModalOpen = action.payload;
        },
        toggleLoginModalOpen(state) {
            state.isLoginModalOpen = !state.isLoginModalOpen;
        },
        toogleWsReconnect(state) {
            state.wsReconnect = !state.wsReconnect;
        },
        toggleIsSetupMode(state, action: PayloadAction<boolean>) {
            state.isSetupMode = action.payload;
        },
        toggleSendRequestModal(state) {
            state.isSendRequestActive = !state.isSendRequestActive;
        },
        toggleRejectRequestModal(state) {
            state.isRejectRequestActive = !state.isRejectRequestActive;
        },
        toggleAcceptRequestModal(state) {
            state.isAcceptRequestActive = !state.isAcceptRequestActive;
        },
        toggleCancelRequestModal(state) {
            state.isCancelRequestActive = !state.isCancelRequestActive;
        },
        toggleWalletConnectStateModal(state, action: PayloadAction<boolean>) {
            state.isWalletConnectStateActive = !state.isWalletConnectStateActive;
            state.connectedSuccessfuly = action.payload;
        },
        toggleOtpVerification(state, action: PayloadAction<boolean>) {
            state.otpEnabled = action.payload;
        },
        changeFinexParticipation(state, action: PayloadAction<string>) {
            state.finexParticipation = action.payload;
        },
        toggleIsOtpOpened(state, action: PayloadAction<boolean>) {
            state.isOtpOpened = action.payload;
        },
    },
});

export const {
    changeTheme,
    changePalette,
    toggleChartRebuild,
    toggleMarketSelector,
    toggleMobileDevice,
    toggleSidebar,
    triggerApplyWindowEnvs,
    toggleDepositModal,
    toggleWithdrawModal,
    toggleWalletConnectModalOpen,
    setWalletConnectModalOpen,
    toggleLoginModalOpen,
    toogleWsReconnect,
    changeConnectionType,
    toggleIsSetupMode,
    changeCustomization,
    toggleSendRequestModal,
    toggleRejectRequestModal,
    toggleAcceptRequestModal,
    toggleCancelRequestModal,
    toggleWalletConnectStateModal,
    toggleOtpVerification,
    changeFinexParticipation,
    toggleIsOtpOpened,
} = globalSettingsSlice.actions;
export const globalSettingsReducer = globalSettingsSlice.reducer;
export default globalSettingsSlice.reducer;
