function isBrowser(): boolean {
    return typeof window !== 'undefined';
}

export interface FinexCustodyAddress {
    [key: string]: string;
}

export interface AppConfig {
    appName: string;
    appLogos?: string;
    appVersion: string;
    blockchain: {
        supportedChainIds: string[];
    };
    languages: string[];
    orderBookSideLimit: string;
    finexUrl: string;
    gRPCUrl: string;
    defaultStorageLimit: string;
    infuraId: string;
    finex_custody_contract_address: string;
    finex_custody_implementation_contract_address: string;
    finex_custody_broker_public_key: string;
    finex_yellow_network_address: string;
    platformSymbol: string;
    platformCharSymbol: string;
    goTrueURL: string;
    goTrueAnon: string;
    countOfRowsPerPage: string;
    alertDisplayTime: string;
    palette?: string;
    finex_custody_mode: string;
    finex_status: string,
    finex_version: string,
    participant_status: string,
    registration_date: number,
}

export interface AppSignerConfig {
    pubKey: string;
    gRPCSignerUrl: string;
}

export function isDevMode(): boolean {
    const isLocalHost = isBrowser() && location.hostname.startsWith('local');
    const isNextDevMod = Boolean(process.env.NEXT_PUBLIC_APP_DEV_MODE);

    return isLocalHost || isNextDevMod;
}

function getWsProtocol(): string {
    if (isBrowser()) {
        return location.protocol === 'http:' ? 'ws://' : 'wss://'
    }
    return ''
}

export function getFinexHostUrl(): string {
    if (isDevMode()) {
        return 'ws://localhost:9003/ws'
    }

    if (isBrowser()) {
        const protocol = getWsProtocol()

        return `${protocol}${location.hostname}/api/v1/finex/ws`
    }

    return ''
}

export function getGoTrueUrl(): string {
    const resource = 'api/v1/auth';

    if (isDevMode()) {
        return `http://localhost:8000/${resource}`
    }

    if (isBrowser()) {
        return `${location.protocol}//${location.hostname}/${resource}`
    }

    return ''
}

export function getGRPCHostUrl(): string {
    if (isDevMode()) {
        return 'http://localhost:50052'
    }

    if (isBrowser()) {
        return `${location.protocol}//${location.hostname}/api/v1-grpc/finex`
    }

    return ''
}

export function getGRPCSignerHostUrl(): string {
    if (isDevMode()) {
        return 'http://localhost:50052'
    }

    if (isBrowser()) {
        return `${location.protocol}//${location.hostname}/api/v1-grpc/signer`
    }

    return ''
}

