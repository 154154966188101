import { AppConfig, getFinexHostUrl, getGoTrueUrl, getGRPCHostUrl } from './utils'

const fallbackConfig: AppConfig = {
    // General configs
    appName: 'Opendax',
    appVersion: '4.0-alpha3',
    languages: ['en', 'ru', 'fr', 'th', 'pt-BR', 'es', 'tr', 'de', 'zh', 'vi', 'ko', 'ja', 'id'],
    // Custody configs
    blockchain: {
        supportedChainIds: [
            '1', // Ethereum Mainnet
            '3', // Ethereum Testnet Ropsten
            '4', // Ethereum Testnet Rinkeby
            '5', // Ethereum Testnet Goerli
            '42', // Ethereum Testnet Kovan
            '56', // Binance Smart Chain Mainnet
            '97', // Binance Smart Chain Testnet
            '137', // Matic(Polygon) Mainnet
            '80001', // Matic Testnet Mumbai
            '31337', // Hardhat Local,
        ],
    },
    infuraId: process.env.NEXT_PUBLIC_INFURA_ID as string,
    finex_custody_contract_address: '',
    finex_custody_implementation_contract_address: '',
    finex_custody_broker_public_key: '',
    finex_yellow_network_address: '',
    // BE configs
    finexUrl: getFinexHostUrl(),
    goTrueURL: getGoTrueUrl(),
    gRPCUrl: getGRPCHostUrl(),
    goTrueAnon: process.env.NEXT_PUBLIC_GOTRUE_ANON || '',
    // UI configs
    orderBookSideLimit: '25',
    defaultStorageLimit: '50',
    platformSymbol: 'usdt',
    platformCharSymbol: '$',
    countOfRowsPerPage: '10',
    alertDisplayTime: '3',
    // Test env configs
    finex_custody_mode: 'demo',
    finex_status: 'restricted',
    finex_version: 'n/a',
    participant_status: '',
    registration_date: 0,
}

export const supportedChains: Record<string, string> = {
    '1': 'Ethereum Mainnet',
    '3': 'Ethereum Testnet Ropsten',
    '4': 'Ethereum Testnet Rinkeby',
    '5': 'Ethereum Testnet Goerli',
    '42': 'Ethereum Testnet Kovan',
    '56': 'Binance Smart Chain Mainnet',
    '97': 'Binance Smart Chain Testnet',
    '137': 'Matic(Polygon) Mainnet',
    '80001': 'Matic Testnet Mumbai',
    '31337': 'Hardhat Local',
}

export default fallbackConfig
