export interface USER_MENU {
  icon: () => JSX.Element
  label: string
  onClick: () => void
}

const ProfileIcon = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 19.2c-2.5 0-4.71-1.28-6-3.2.03-2 4-3.1 6-3.1s5.97 1.1 6 3.1a7.232 7.232 0 0 1-6 3.2ZM12 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0-3a10 10 0 1 0 10 10c0-5.53-4.5-10-10-10Z"
        fill="#090909"
        opacity={0.48}
      />
    </svg>
  )
}

const LogoutIcon = () => {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.92 15.59 7.33 13H17v-2H7.33l2.59-2.59L8.5 7l-5 5 5 5 1.42-1.41ZM5 3a2 2 0 0 0-2 2v4.67l2-2V5h14v14H5v-2.67l-2-2V19a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5c0-1.11-.89-2-2-2H5Z"
        fill="#090909"
        opacity={0.48}
      />
    </svg>
  )
}

export const DEFAULT_USER_MENU: USER_MENU[] = [
  {
    icon: ProfileIcon,
    label: 'Profile',
    onClick: () => console.log('onClick Profile'),
  },
  {
    icon: LogoutIcon,
    label: 'Logout',
    onClick: () => console.log('onClick logout'),
  },
]
