import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Market } from '@/web-sdk/types';
import { formatMarkets } from './helpers';

export interface MarketsState {
    markets: Market[];
    currentMarket?: Market;
    marketsLoading: boolean;
}

export const initialMarketsState: MarketsState = {
    markets: [],
    marketsLoading: true,
};

const marketsSlice = createSlice({
    name: 'markets',
    initialState: initialMarketsState,
    reducers: {
        saveMarkets(state, action: PayloadAction<(string | number)[][]>) {
            const list = formatMarkets(action.payload);

            state.markets = list;
            state.marketsLoading = false;
        },
        setCurrentMarket(state, action: PayloadAction<Market>) {
            state.currentMarket = action.payload;
        },
        initializeCurrentMarket(state, action: PayloadAction<Market>) {
            if (!state.currentMarket) {
                state.currentMarket = action.payload;
            }
        },
        resetMarketsLoading(state) {
            state.marketsLoading = false;
        },
    },
});

export const {
    saveMarkets,
    setCurrentMarket,
    initializeCurrentMarket,
    resetMarketsLoading,
} = marketsSlice.actions;
export const marketsReducer = marketsSlice.reducer;
export default marketsSlice.reducer;
