import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { alertsReducer } from '@/web-sdk/providers/ReduxProvider/features/alerts/alertsSlice';
import { globalSettingsReducer } from '@/web-sdk/providers/ReduxProvider/features/globalSettings/globalSettingsSlice';
import { marketsReducer } from '@/web-sdk/providers/ReduxProvider/features/markets/marketsSlice';
import { symbolsReducer } from '@/web-sdk/providers/ReduxProvider/features/symbols/symbolsSlice';
import { tickersReducer } from '@/web-sdk/providers/ReduxProvider/features/tickers/tickersSlice';
import { tokensReducer } from '@/web-sdk/providers/ReduxProvider/features/tokens/tokensSlice';
import { userReducer } from '@/web-sdk/providers/ReduxProvider/features/user/userSlice';
import { articleReducer } from '@/web-sdk/providers/ReduxProvider/features/article/articleSlice';
import { assetArticleReducer } from '@/web-sdk/providers/ReduxProvider/features/assetArticle/assetArticleSlice';
import { categoryReducer } from '@/web-sdk/providers/ReduxProvider/features/category/categorySlice';
import { tagReducer } from '@/web-sdk/providers/ReduxProvider/features/tag/tagSlice';
import { fundamentalAnalysisReducer } from '@/web-sdk/providers/ReduxProvider/features/fundamentalAnalysis/fundamentalAnalysisSlice';

export const store = configureStore({
    reducer: {
        alerts: alertsReducer,
        globalSettings: globalSettingsReducer,
        markets: marketsReducer,
        symbols: symbolsReducer,
        tickers: tickersReducer,
        tokens: tokensReducer,
        user: userReducer,
        article: articleReducer,
        assetArticle: assetArticleReducer,
        category: categoryReducer,
        tag: tagReducer,
        fundamentalAnalysis: fundamentalAnalysisReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
