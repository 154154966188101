import Link, { LinkProps } from 'next/link';
import { FC } from 'react';
import { DEFAULT_LANG } from 'web-sdk/constants';
import { useParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { useIsAppRouter } from '@/hooks/useIsAppRouter';

interface LocaleLinkProps extends Omit<LinkProps, 'locale'> {
    href: string;
    className?: string;
    children?: React.ReactNode;
    rel?: string;
    target?: string;
    locale?: string;
    onMouseOver?: () => void;
    onMouseLeave?: () => void;
}

const LocalePagesLink: FC<LocaleLinkProps> = ({ href, children, locale, ...props }) => {
    const router = useRouter();
    const lowercasedLocale = (locale?.toLowerCase() || router?.locale || DEFAULT_LANG)?.toLowerCase();

    return (
        <Link href={href} locale={lowercasedLocale} {...props}>
            {children}
        </Link>
    );
};

const LocaleAppLink: FC<LocaleLinkProps> = ({ href, children, locale, ...props }) => {
    const params = useParams();
    const lowercasedLocale = (locale?.toLowerCase() || (params?.locale as string) || DEFAULT_LANG)?.toLowerCase();

    return (
        <Link href={href} locale={lowercasedLocale} {...props}>
            {children}
        </Link>
    );
};

export const LocaleLink: FC<LocaleLinkProps> = (props) => {
    const isAppRouter = useIsAppRouter();

    return isAppRouter ? <LocaleAppLink {...props} /> : <LocalePagesLink {...props} />;
};

export default LocaleLink;
