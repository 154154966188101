'use client';

import { YellowLogo } from '@/assets/images/VaultLogo';
import { SidebarProps, navigationApp, navigationAppItem } from '@/components/Sidebar/types';
import AccountStore from '@/store/AccountStore';
import ModalStore from '@/store/ModalStore';
import { isBrowser } from '@/web-sdk/helpers/isBrowser';
import classNames from 'classnames';
import {
    navigation,
    navigationAuthorizedYellow,
    navigationMobile,
    navigationPro,
    navigationVault,
} from 'configs/navigation';
import { useTranslation } from 'next-i18next';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React, { PropsWithChildren, useCallback, useEffect, useMemo } from 'react';
import { useSnapshot } from 'valtio';
import { HeaderProps } from 'web-sdk/components/Header';
import { Layout as SharedLayout } from 'web-sdk/components/Layout';

// Dynamically import the Send component
const Send = dynamic(() => import('@/widgets/Assets/modal/send'), { ssr: false });
const Header = dynamic(() => import('@/widgets/Header'));
const CustomFooter = dynamic(() => import('./CustomFooter'));

interface HeaderNavigations {
    name: string | React.ReactNode;
    href: string;
}

export interface HeaderOptions {
    navigations?: HeaderNavigations[];
    customHeaderContent?: React.ReactNode;
    responseIsMobile?: boolean;
}

interface LayoutProps {
    className?: string;
    headerOptions?: HeaderOptions;
    hideFooter?: boolean;
    hideHeader?: boolean;
    hideOptionsMenu?: boolean;
    isVaultLayout?: boolean;
    vaultHeaderProps?: HeaderProps;
}

// const currentMarketSelector = (state: RootState) => state.markets.currentMarket
// const marketsSelector = (state: RootState) => state.markets.markets

export function AppLayout({
    className,
    headerOptions,
    hideFooter,
    hideHeader,
    hideOptionsMenu = false,
    children,
    vaultHeaderProps,
    isVaultLayout = false,
}: PropsWithChildren<LayoutProps>): JSX.Element {
    const { smartAccount, publicClient } = useSnapshot(AccountStore.state);
    const {
        send: { open: isSendOpen },
    } = useSnapshot(ModalStore.state);

    const closeOpenSendHandler = useCallback(() => {
        ModalStore.closeSend();
    }, []);

    const pathname = usePathname();
    const { t, ready } = useTranslation('common');

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 1);
        }
    }, [pathname]);

    useEffect(() => {
        if (isBrowser()) {
            const element = document.querySelector('a[aria-label="Trade"]');
            const elementWallet = document.querySelector('a[aria-label="Wallet"]');

            if (element && elementWallet) {
                element.className =
                    'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10 flex-col';
                elementWallet.className =
                    'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10 flex-col';
            }
        }
    }, []);

    const getTranslation = (key: string, fallback: string) => (ready ? t(key) : fallback);

    const navigations = useMemo((): navigationApp[] => {
        return [
            {
                app: '',
                pathnames: navigationAuthorizedYellow.map<navigationAppItem>((nav: navigationAppItem) => {
                    return {
                        ...nav,
                        name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                        path: nav.path,
                        skipUseCurrentPath: true,
                    };
                }),
            },
            {
                app: getTranslation('page.sidebar.navigation.separator.vault', 'Vault'),
                pathnames: navigationVault
                    .filter((nav: navigationAppItem) => !nav.bottom)
                    .map<navigationAppItem>((nav: navigationAppItem) => {
                        if (nav.submenus?.length) {
                            return {
                                ...nav,
                                name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                                submenus: nav.submenus.map((submenu: any) => {
                                    return {
                                        ...submenu,
                                        name: getTranslation(
                                            `page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`,
                                            submenu.fallback,
                                        ),
                                    };
                                }),
                                skipUseCurrentPath: true,
                            };
                        }

                        return {
                            ...nav,
                            name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                            path: nav.path,
                            skipUseCurrentPath: true,
                        };
                    }),
            },
            {
                app: getTranslation('page.sidebar.navigation.separator.pro', 'Pro'),
                pathnames: navigationPro
                    .filter((nav: navigationAppItem) => !nav.bottom)
                    .map<navigationAppItem>((nav: navigationAppItem) => {
                        if (nav.submenus?.length) {
                            return {
                                ...nav,
                                name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                                submenus: nav.submenus.map((submenu: any) => {
                                    return {
                                        ...submenu,
                                        name: getTranslation(
                                            `page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`,
                                            submenu.fallback,
                                        ),
                                    };
                                }),
                                skipUseCurrentPath: true,
                            };
                        }

                        return {
                            ...nav,
                            name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                            path: nav.path,
                            skipUseCurrentPath: true,
                        };
                    }),
            },
            {
                app: 'settings',
                pathnames: navigation
                    .filter((nav: navigationAppItem) => nav.bottom)
                    .map<navigationAppItem>((nav: navigationAppItem) => {
                        return {
                            ...nav,
                            name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                            path: nav.path,
                            skipUseCurrentPath: true,
                        };
                    }),
                bottom: true,
            },
        ];
    }, [ready]);

    const mobileNavigation = useMemo((): navigationApp[] => {
        if (!navigationMobile) return [];

        return [
            {
                app: 'mainapp',
                pathnames: navigationMobile.map<navigationAppItem>((nav: navigationAppItem) => {
                    if (nav.submenus?.length) {
                        return {
                            ...nav,
                            name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                            submenus: nav.submenus.map((submenu: any) => {
                                return {
                                    ...submenu,
                                    name: getTranslation(
                                        `page.sidebar.navigation.${nav.name.toLowerCase()}.submenu.${submenu.name.toLowerCase()}`,
                                        submenu.fallback,
                                    ),
                                };
                            }),
                        };
                    }

                    return {
                        ...nav,
                        name: getTranslation(`page.sidebar.navigation.${nav.name.toLowerCase()}`, nav.fallback),
                        path: nav.path,
                    };
                }),
            },
        ];
    }, [ready]);

    const yellowLogo = useMemo(() => {
        return (
            <YellowLogo classNames="max-sm:w-20 max-lg:w-24 focus:outline-none focus:ring-none text-neutral-control-layer-color-100" />
        );
    }, []);

    const sidebarProps: SidebarProps = useMemo(() => {
        const rootUrl = process.env.NEXT_PUBLIC_ROOT_URL || '';

        return {
            currentApp: 'mainapp',
            isVault: isVaultLayout,
            navigations,
            mobileNavigation: mobileNavigation,
            classNames: 'bg-navbar-background-color sm:border-r border-divider-color-20 fixed z-[30] with-scrollbar',
            bottomClasses:
                'fixed w-screen bottom-0 z-30 flex-shrink-0 md:hidden flex h-[64px] bg-navbar-background-color border-t border-divider-color-20 w-full left-0',
            navActiveClassNames: 'bg-navbar-control-bg-color-10 text-navbar-control-layer-color-60',
            navInactiveClassNames: 'text-neutral-control-layer-color-60 hover:bg-navbar-control-bg-color-10',
            navOverlayClasses: 'relative bg-navbar-background-color flex-1 flex flex-col max-w-[260px] pt-5 pb-4',
            navOverlayClassNames:
                'relative no-underline duration-150 group flex items-center mt-1 px-2 py-2 text-md font-metro-bold rounded-md',
            navMinimizedClassNames:
                'no-underline group flex items-center mt-1 px-2 py-2 text-xs font-metro-semibold rounded-md',
            isLoggedin: false,
            logo: yellowLogo,
            linkOnLogo: '/',
            showMobileSidebarToggler: true,
            mobileNavbarClasses: 'text-sm font-metro-semibold text-neutral-control-layer-color-50 mx-2.5 my-1 rounded',
            mobileNavbarActiveClasses: 'bg-navbar-control-bg-color-10',
            mobileSpanActiveColor: 'text-navbar-control-layer-color-60',
            showNavGroupTitles: true,
            navMoreLabel: getTranslation('page.sidebar.navigation.more', 'More'),
            exceptedDefaultActivePathnames: [
                '/404',
                '/trading',
                '/assets',
                '/news',
                '/articles',
                '/settings',
                '/balances',
                `${rootUrl}/seasons`,
                `${rootUrl}/vault`,
                `${rootUrl}/quests`,
                `${rootUrl}/leaderboard`,
                `${rootUrl}/applications`,
            ],
        };
    }, [navigations, mobileNavigation, yellowLogo, ready, isVaultLayout]);

    const customHeaderProps = useMemo(() => {
        let headerProps: HeaderProps = {
            headerLogoIcon: yellowLogo,
        };

        if (headerOptions) {
            headerProps = {
                ...headerProps,
                options: headerOptions,
            };
        }
        return headerProps;
    }, [headerOptions, yellowLogo]);

    const renderLayout = useMemo(() => {
        return (
            <SharedLayout
                containerClassName={classNames(className, {
                    'flex mt-0 flex-col mb-28 mx-auto max-md:px-0 max-xl:px-2 w-full 3xl:w-[1250px] 2xl:w-[1155px] xl:w-[921px] xl:justify-center':
                        isVaultLayout,
                })}
                sidebarProps={sidebarProps}
                customHeader={
                    hideHeader ? null : (
                        <Header
                            {...vaultHeaderProps}
                            hideOptionsMenu={hideOptionsMenu}
                            {...customHeaderProps}
                            isVaultLayout={isVaultLayout}
                            actionButtonLabel={getTranslation('header.button.vault', 'Vault')}
                        />
                    )
                }
                customFooter={hideFooter ? null : <CustomFooter isVaultLayout={isVaultLayout} />}
                mainClassName="flex-1 flex flex-col relative overflow-y-auto overflow-x-hidden focus:outline-none md:ml-20">
                {children}
                {isSendOpen && (
                    <Send
                        smartAccount={smartAccount}
                        handleCloseModal={closeOpenSendHandler}
                        publicClient={publicClient}
                    />
                )}
            </SharedLayout>
        );
    }, [
        hideHeader,
        vaultHeaderProps,
        customHeaderProps,
        sidebarProps,
        isVaultLayout,
        children,
        isSendOpen,
        smartAccount,
        closeOpenSendHandler,
        publicClient,
        hideFooter,
        hideOptionsMenu,
        className,
    ]);

    return renderLayout;
}

export const Layout = React.memo(AppLayout);
