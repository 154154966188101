import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Article } from 'types';

export interface ArticleState {
    article: Article;
    isLoading: boolean;
}

export const EMPTY_ARTICLE: Article = {
    id: 0,
    slug: '',
    content: '',
    title: '',
    author: '',
    description: '',
    asset: null,
    state: '',
    image_url: null,
    tags: [],
    locale: null,
    localizations: []
}

const initialArticleState: ArticleState = {
    article: EMPTY_ARTICLE,
    isLoading: false,
};

const articleSlice = createSlice({
    name: 'articles',
    initialState: initialArticleState,
    reducers: {
        setArticle(state, action: PayloadAction<Article>) {
            state.article = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setArticle } = articleSlice.actions;
export const articleReducer = articleSlice.reducer;
export default articleSlice.reducer;
