const HttpBackend = require('i18next-http-backend/cjs')
const ChainedBackend = require('i18next-chained-backend').default
const LocalStorageBackend = require('i18next-localstorage-backend').default

const isBrowser = typeof window !== 'undefined'
const isDev = process.env.NODE_ENV === 'development'

module.exports = {
  backend: {
    backendOptions: [{ expirationTime: isDev ? 0 : 60 * 60 * 1000 }, {}], // 1 hour
    backends: isBrowser ? [LocalStorageBackend, HttpBackend] : [],
  },
  partialBundledLanguages: isBrowser && true,
  i18n: {
    locales: ['en', 'en-US', 'en-GB', 'ru', 'fr', 'fr-FR', 'th', 'pt-BR', 'es', 'tr', 'de', 'zh', 'vi', 'ko', 'ja', 'id'], // Add more locales as needed
    defaultLocale: 'en',
    localeDetection: false, // Disable automatic locale detection and redirection
  },
  fallbackLng: {
    'en-US': ['en'],
    'en-GB': ['en'],
    'fr-FR': ['fr'],
    default: ['en'],
  },
  nonExplicitSupportedLngs: true,
  serializeConfig: false,
  use: isBrowser ? [ChainedBackend] : [],
  react: {
    useSuspense: false, // Optional: Set to true if you want to use React Suspense
  },
};
