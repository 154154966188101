export interface IYellowLogoProps {
    classNames?: string;
}

export const YellowLogo: React.FC<IYellowLogoProps> = ({ classNames }: IYellowLogoProps) => {
    return (
        <svg
            width="128"
            height="40"
            viewBox="0 0 128 40"
            fill="none"
            className={classNames}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M41.4702 24.4167C41.3172 24.311 41.1172 24.3345 40.9995 24.4754C39.4226 26.3072 37.6928 26.7886 35.9041 26.7886C31.8207 26.7886 29.3848 24.0057 29.3848 20.6005C29.3848 17.1952 32.1149 14.4123 35.4569 14.4123C38.2341 14.4123 40.2817 16.0445 41.1643 18.3929C41.2231 18.5573 41.1054 18.7452 40.9171 18.7452H34.6096C34.4096 18.7452 34.2566 18.8978 34.2566 19.0975V22.1035C34.2566 22.3031 34.4096 22.4557 34.6096 22.4557H41.2819H46.2126C46.448 22.4557 46.6363 22.2913 46.6716 22.0565C46.7539 21.5516 46.7892 21.0936 46.7892 20.6005C46.7892 14.5415 41.7056 9.65674 35.504 9.65674C29.3024 9.65674 24.2188 14.5415 24.2188 20.6005C24.2188 26.6594 29.3024 31.5442 35.504 31.5442C39.5521 31.5442 42.7176 29.8885 44.8358 27.2113C44.9299 27.0939 44.9064 26.9295 44.7887 26.8356L41.4702 24.4167Z"
                fill="currentColor"
            />
            <path
                d="M56.2735 23.7356V0.9675C56.2735 0.767883 56.1205 0.615234 55.9205 0.615234H50.978C50.778 0.615234 50.625 0.767883 50.625 0.9675V23.1837C50.625 28.6556 52.4608 31.1097 58.0034 31.1097H58.6388C58.8977 31.1097 59.1095 30.8984 59.1095 30.64V26.6829C59.1095 26.4833 58.9566 26.3306 58.7565 26.3306H58.6741C57.2385 26.3306 56.2735 25.6966 56.2735 23.7356Z"
                fill="currentColor"
            />
            <path
                d="M68.0421 23.7356V0.9675C68.0421 0.767883 67.8891 0.615234 67.689 0.615234H62.7466C62.5465 0.615234 62.3936 0.767883 62.3936 0.9675V23.1837C62.3936 28.6556 64.2293 31.1097 69.7719 31.1097H70.4074C70.6663 31.1097 70.8781 30.8984 70.8781 30.64V26.6829C70.8781 26.4833 70.7251 26.3306 70.525 26.3306H70.4427C69.007 26.3306 68.0421 25.6966 68.0421 23.7356Z"
                fill="currentColor"
            />
            <path
                d="M3.48389 39.2001V34.9376C3.48389 34.738 3.63687 34.5854 3.83692 34.5854H5.79036C7.02597 34.5854 8.1439 33.4111 8.70875 32.2369C8.70875 32.2252 8.72052 32.2134 8.72052 32.2017L8.94411 31.5207C9.03825 31.2506 9.02648 30.957 8.90881 30.6987L0.0830158 10.4199C-0.0228937 10.1851 0.153621 9.92676 0.412511 9.92676H5.4844C5.67268 9.92676 5.83743 10.0324 5.90804 10.2086L11.9684 23.6064C12.0508 23.7943 12.3214 23.7943 12.4038 23.6064L18.0523 10.2321C18.1229 10.0559 18.2994 9.95024 18.4877 9.95024H23.6185C23.8773 9.95024 24.0421 10.2086 23.948 10.4434L13.6394 34.3623C12.3332 37.5327 9.34421 39.5758 5.80213 39.5758H3.84869C3.64864 39.5523 3.48389 39.3997 3.48389 39.2001Z"
                fill="currentColor"
            />
            <path
                d="M119.713 10.1029C119.772 10.0089 119.901 9.93848 120.019 9.93848H124.832H124.973C125.079 9.93848 125.326 10.0559 125.256 10.1498L110.358 30.7456C110.264 30.8748 110.111 30.957 109.946 30.957H105.415C105.168 30.957 104.886 30.6752 105.027 30.4638L119.713 10.1029Z"
                fill="#FCD000"
            />
            <path
                d="M96.7663 9.9502H101.815C102.003 9.9502 102.179 10.0676 102.25 10.2438L110.311 30.4873C110.405 30.7221 110.229 30.9687 109.981 30.9687H105.18C104.992 30.9687 104.815 30.8513 104.745 30.6752L96.4486 10.4316C96.3427 10.1968 96.5074 9.9502 96.7663 9.9502Z"
                fill="currentColor"
            />
            <path
                d="M108.346 9.9502H113.394C113.582 9.9502 113.759 10.0676 113.829 10.2438L121.89 30.4873C121.984 30.7221 121.808 30.9687 121.561 30.9687H116.759C116.571 30.9687 116.395 30.8513 116.324 30.6752L108.028 10.4316C107.934 10.1968 108.098 9.9502 108.346 9.9502Z"
                fill="currentColor"
            />
            <path
                d="M127.327 14.6471L125.48 10.232C125.409 10.0559 125.232 9.93848 125.044 9.93848H120.008C119.749 9.93848 119.584 10.1968 119.678 10.4316L121.573 14.8467C121.643 15.0228 121.82 15.1285 122.008 15.1285H126.998C127.257 15.1403 127.421 14.8819 127.327 14.6471Z"
                fill="currentColor"
            />
            <path
                d="M86.3757 18.0876H82.2688C81.9863 18.0876 81.751 18.3225 81.751 18.6043V22.3735C81.751 22.6554 81.9863 22.8902 82.2688 22.8902H86.3757C86.6581 22.8902 86.8935 22.6554 86.8935 22.3735V18.6043C86.8935 18.3225 86.6581 18.0876 86.3757 18.0876Z"
                fill="#FCD000"
            />
            <path
                d="M78.2204 18.252C78.4558 18.252 78.6558 18.1111 78.7617 17.8997C79.7149 15.8214 81.8566 14.3301 84.2926 14.3301C86.7402 14.3301 88.882 15.7627 89.8234 17.888C89.9175 18.0994 90.1293 18.2403 90.3647 18.2403H94.9306C95.1777 18.2403 95.3424 18.0407 95.2718 17.7941C94.0127 13.0737 89.6351 9.53931 84.3043 9.53931C78.9736 9.53931 74.5842 13.062 73.3368 17.7941C73.278 18.0407 73.431 18.2403 73.6781 18.2403H78.2204V18.252Z"
                fill="currentColor"
            />
            <path
                d="M90.3998 22.7845C90.1644 22.7845 89.9526 22.9254 89.8585 23.1367C88.9053 25.2621 86.7636 26.6946 84.3277 26.6946C81.8917 26.6946 79.75 25.2033 78.7968 23.125C78.7027 22.9136 78.4909 22.7727 78.2555 22.7727H73.7132C73.4661 22.7727 73.3013 22.9723 73.3719 23.2189C74.6311 27.9393 79.0086 31.4737 84.3394 31.4737C89.6702 31.4737 94.0596 27.951 95.307 23.2189C95.3658 22.9723 95.2128 22.7727 94.9657 22.7727H90.3998V22.7845Z"
                fill="currentColor"
            />
        </svg>
    );
};
