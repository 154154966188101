import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from 'types';

export interface CategoryState {
    category: Category;
    isLoading: boolean;
}

export const EMPTY_CATEGORY: Category = {
  category: '',
  category_id: '',
  name: '',
  title: '',
  description: '',
  locale: null,
  localizations: []
}

const initialCategoryState: CategoryState = {
    category: EMPTY_CATEGORY,
    isLoading: false,
};

const categorySlice = createSlice({
    name: 'category',
    initialState: initialCategoryState,
    reducers: {
        setCategory(state, action: PayloadAction<Category>) {
            state.category = action.payload;
            state.isLoading = false;
        },
    },
});

export const { setCategory } = categorySlice.actions;
export const categoryReducer = categorySlice.reducer;
export default categorySlice.reducer;
