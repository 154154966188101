import React, { ReactNode } from 'react';
import { ColorThemeProvider } from '@/web-sdk/providers/ColorThemeProvider';
import { ReduxProvider } from '@/web-sdk/providers/ReduxProvider';

interface CoreProviderProps {
    children: JSX.Element | JSX.Element[] | ReactNode;
}

export const CoreProvider: React.FC<CoreProviderProps> = ({ children }) => {
    return (
        <ReduxProvider>
            <ColorThemeProvider>
                <>{children}</>
            </ColorThemeProvider>
        </ReduxProvider>
    );
};
