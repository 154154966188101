import { CircleStarIcon } from '@/assets/images/CircleStarIcon';
import { VaultSidebarIcons } from '@/assets/images/VaultSidebarIcons';
import { SidebarIcons } from 'assets/images/SidebarIcons';

const rootUrl = process.env.NEXT_PUBLIC_ROOT_URL || '';

const navigationElements = {
    home: {
        name: 'Home',
        fallback: 'Home',
        path: '/',
        defaultIcon: <SidebarIcons name="home" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <SidebarIcons name="home" primaryColor="var(--navbar-control-layer-color-60)" />,
    },
    assets: {
        name: 'Assets',
        fallback: 'Rankings',
        path: '/assets',
        defaultIcon: <SidebarIcons name="assets" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <SidebarIcons name="assets" primaryColor="var(--navbar-control-layer-color-60)" />,
    },
    news: {
        name: 'News',
        fallback: 'News',
        path: '/news',
        defaultIcon: <SidebarIcons name="news" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <SidebarIcons name="news" primaryColor="var(--navbar-control-layer-color-60)" />,
    },
    articles: {
        name: 'Articles',
        fallback: 'Articles',
        path: '/articles',
        defaultIcon: <SidebarIcons name="articles" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <SidebarIcons name="articles" primaryColor="var(--navbar-control-layer-color-60)" />,
    },
    trade: {
        name: 'Trade',
        fallback: 'Trade',
        path: 'https://terminal.yellow.com',
        activeIcon: <SidebarIcons name="trading" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultIcon: <SidebarIcons name="trading" primaryColor="var(--neutral-control-layer-color-40)" />,
        newTab: true,
    },
    settings: {
        name: 'Settings',
        fallback: 'Settings',
        path: '/settings',
        activeIcon: <SidebarIcons name="settings" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultIcon: <SidebarIcons name="settings" primaryColor="var(--neutral-control-layer-color-40)" />,
        bottom: true,
    },
    wallet: {
        name: 'Vault',
        fallback: 'Vault',
        path: `${rootUrl}/vault`,
        activeIcon: <SidebarIcons name="wallet" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultIcon: <SidebarIcons name="wallet" primaryColor="var(--neutral-control-layer-color-40)" />,
        newTab: false,
        defaultLocale: 'en',
    },
    dashboard: {
        name: 'Dashboard',
        fallback: 'Dashboard',
        path: `${rootUrl}/vault`,
        defaultIcon: <VaultSidebarIcons name="dashboard" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <VaultSidebarIcons name="dashboard" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
    seasons: {
        name: 'Seasons',
        fallback: 'Seasons',
        path: `${rootUrl}/seasons`,
        defaultIcon: <CircleStarIcon className="w-[22px] h-[22px]" fillColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <CircleStarIcon className="w-[22px] h-[22px]" fillColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
    apps: {
        name: 'Apps',
        fallback: 'Apps',
        path: `${rootUrl}/applications`,
        defaultIcon: <VaultSidebarIcons name="applications" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <VaultSidebarIcons name="applications" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
    quests: {
        name: 'Quests',
        fallback: 'Quests',
        path: `${rootUrl}/quests`,
        defaultIcon: <VaultSidebarIcons name="assets" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <VaultSidebarIcons name="assets" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
    leagues: {
        name: 'Leagues',
        fallback: 'Leagues',
        path: `${rootUrl}/leaderboard`,
        defaultIcon: <VaultSidebarIcons name="leaderboard" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <VaultSidebarIcons name="leaderboard" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
    vaultSettings: {
        name: 'Vault Settings',
        fallback: 'Vault Settings',
        path: `${rootUrl}/vault-settings`,
        defaultIcon: <VaultSidebarIcons name="settings" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <VaultSidebarIcons name="settings" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
    faq: {
        name: 'FAQ',
        fallback: 'FAQ',
        path: `${rootUrl}/seasons#FAQ`,
        defaultIcon: <VaultSidebarIcons name="FAQ" primaryColor="var(--neutral-control-layer-color-40)" />,
        activeIcon: <VaultSidebarIcons name="FAQ" primaryColor="var(--navbar-control-layer-color-60)" />,
        defaultLocale: 'en',
    },
};

export const navigationLoggedin = [navigationElements['trade'], navigationElements['wallet']];

export const navigation = [
    navigationElements['trade'],
    navigationElements['dashboard'],
    navigationElements['seasons'],
    navigationElements['apps'],
    navigationElements['quests'],
    navigationElements['leagues'],
    navigationElements['vaultSettings'],

    navigationElements['settings'],
];

export const navigationMobile = [
    navigationElements['home'],
    navigationElements['news'],
    navigationElements['wallet'],
    navigationElements['seasons'],
];

export const navigationYellow = [
    navigationElements['home'],
    navigationElements['news'],
    navigationElements['articles'],
];

export const navigationAuthorizedYellow = [
    navigationElements['home'],
    navigationElements['news'],
    navigationElements['articles'],
];

export const navigationVault = [
    navigationElements['wallet'],
    navigationElements['seasons'],
    navigationElements['leagues'],
    navigationElements['apps'],
];

export const navigationPro = [navigationElements['assets']];
