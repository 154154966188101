import { proxy, ref } from 'valtio';
import { Chain, Transport } from 'viem';

import { SmartAccountClient } from 'permissionless';
import { SmartAccount } from 'permissionless/accounts';
import { EntryPoint } from 'permissionless/types';

// FIXME: issue with ref and types.
// interface IAccount {
//   accountAddress: string;
//   smartAccount: SmartAccountClient<Transport, Chain, SmartAccount> | null;
//   kernelAccount: SmartAccount | null;
// }

interface IAccount {
    accountAddress: string;
    accountIsLoading: boolean;
    smartAccount: any;
    kernelAccount: any;
    publicClient: any;
    walletClient: any;
    signer: any;
}

const state = proxy<IAccount>({
    accountAddress: '',
    accountIsLoading: false,
    // Wrap the initial values with `ref()` to ensure they are treated as references
    smartAccount: ref({}),
    kernelAccount: ref({}),
    publicClient: ref({}),
    walletClient: ref({}),
    signer: ref({}),
});

const AccountStore = {
    state,
    setAccountAddress(accountAddress: string) {
        state.accountAddress = accountAddress;
    },
    setAccountIsLoading(accountIsLoading: boolean) {
        state.accountIsLoading = accountIsLoading;
    },
    setWalletClient(walletClient: any) {
        state.walletClient = ref(walletClient);
    },
    // Use the same setters, as `ref()` doesn't change how you interact with these properties
    setSmartAccount(smartAccount: SmartAccountClient<EntryPoint, Transport, Chain, SmartAccount<EntryPoint>> | {}) {
        state.smartAccount = ref(smartAccount); // `ref()` ensures the reference is preserved
    },
    setKernelAccount(kernelAccount: SmartAccount<EntryPoint> | {}) {
        state.kernelAccount = ref(kernelAccount);
    },
    setPublicClient(publicClient: any) {
        state.publicClient = ref(publicClient);
    },
    setSigner(signer: any) {
        state.signer = ref(signer);
    },
};

export default AccountStore;
