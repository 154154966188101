import React from 'react';

export interface ICircleStarIconProps {
    className?: string;
    fillColor?: string;
}

export const CircleStarIcon: React.FC<ICircleStarIconProps> = ({
    className,
    fillColor = 'var(--primary-cta-color-60)',
}) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className={className}
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.0061 4.91213C13.4046 4.90774 14.7729 5.31813 15.9381 6.0914C17.1033 6.86467 18.013 7.96609 18.5522 9.25638C19.0914 10.5467 19.2359 11.9679 18.9674 13.3403C18.6989 14.7127 18.0294 15.9747 17.0437 16.9666C16.058 17.9586 14.8003 18.6359 13.4296 18.9131C12.0589 19.1902 10.6368 19.0546 9.34312 18.5236C8.04946 17.9925 6.94234 17.0897 6.16176 15.9294C5.38118 14.7691 4.96219 13.4034 4.95779 12.005C4.95486 11.0764 5.13485 10.1564 5.48748 9.29747C5.84012 8.4385 6.35848 7.65741 7.01298 6.99878C7.66748 6.34014 8.4453 5.81688 9.30203 5.45885C10.1588 5.10082 11.0776 4.91505 12.0061 4.91213ZM12.0061 2.13281C10.059 2.13282 8.15547 2.70995 6.53614 3.79129C4.91682 4.87263 3.65432 6.40967 2.90816 8.20821C2.16199 10.0068 1.96564 11.9861 2.3439 13.8962C2.72216 15.8063 3.65807 17.5614 5.03338 18.9398C6.40869 20.3182 8.1617 21.2581 10.0709 21.6407C11.9802 22.0232 13.9599 21.8313 15.7602 21.0892C17.5604 20.3471 19.1003 19.0881 20.1853 17.4712C21.2702 15.8543 21.8517 13.9521 21.8561 12.005C21.859 10.7096 21.6064 9.42635 21.1127 8.22873C20.619 7.03112 19.8939 5.94265 18.979 5.02564C18.064 4.10864 16.9772 3.38111 15.7807 2.88471C14.5842 2.38832 13.3015 2.13281 12.0061 2.13281Z"
                fill={fillColor}
            />
            <path
                d="M12.0007 5.99919L13.8633 10.1367L18.0007 11.9992L13.8633 13.8617L12.0007 17.9992L10.1382 13.8617L6.00073 11.9992L10.1382 10.1367L12.0007 5.99919Z"
                fill={fillColor}
            />
        </svg>
    );
};

export interface ICircleStarDexyfiIconProps {
    className?: string;
    fillColor?: string;
    strokeColor?: string;
}

export const CircleStarDexyfiIcon: React.FC<ICircleStarDexyfiIconProps> = ({
    className,
    fillColor = 'white',
    strokeColor = 'black',
}) => {
    return (
        <svg
            className={className}
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.6084 8.09814L19.1779 13.8061L19.2557 13.979L19.4286 14.0568L25.1366 16.6263L19.4286 19.1958L19.2557 19.2737L19.1779 19.4465L16.6084 25.1545L14.0389 19.4465L13.961 19.2737L13.7882 19.1958L8.08016 16.6263L13.7882 14.0568L13.961 13.979L14.0389 13.8061L16.6084 8.09814ZM23.2806 6.61325C21.3055 5.30249 18.986 4.60684 16.6156 4.61428C15.0416 4.61923 13.4841 4.93413 12.0319 5.54102C10.5796 6.14791 9.26114 7.0349 8.1517 8.15134C7.04226 9.26778 6.16358 10.5918 5.56583 12.0478C4.96809 13.5039 4.66299 15.0634 4.66795 16.6373C4.67541 19.0078 5.38563 21.3228 6.70879 23.2896C8.03195 25.2564 9.90862 26.7867 12.1015 27.6869C14.2944 28.5872 16.7049 28.8169 19.0284 28.3472C21.3519 27.8774 23.4838 26.7292 25.1547 25.0478C26.8256 23.3663 27.9604 21.2272 28.4155 18.9008C28.8707 16.5744 28.6258 14.1654 27.7117 11.9782C26.7977 9.79103 25.2557 7.92401 23.2806 6.61325ZM8.00946 3.70942C10.5577 2.0078 13.553 1.09962 16.6172 1.09961C18.6556 1.0996 20.674 1.50168 22.5568 2.28281C24.4397 3.06395 26.1499 4.2088 27.5897 5.65182C29.0295 7.09483 30.1705 8.80767 30.9473 10.6923C31.7242 12.5768 32.1217 14.5962 32.1171 16.6346C32.1102 19.6987 31.1953 22.692 29.4879 25.2364C27.7806 27.7807 25.3574 29.762 22.5245 30.9298C19.6917 32.0975 16.5763 32.3995 13.5719 31.7975C10.5675 31.1955 7.8089 29.7165 5.64469 27.5474C3.48047 25.3783 2.00771 22.6164 1.41247 19.6107C0.817233 16.6049 1.12622 13.4902 2.3004 10.66C3.47458 7.82975 5.46127 5.41104 8.00946 3.70942Z"
                fill={fillColor}
                stroke={strokeColor}
            />
        </svg>
    );
};
